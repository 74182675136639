import React , { useState } from 'react';
import './App.css';
import Word from './components/Word';

const App = () => {
  return (
    <div className="container">
      <Word word="AE5MS" />
      <Word word="YKTOQEEY" />
      <Word word="ÄHI6FÅÅÖ6I68H" />
      <Word word="DNNM++UAOA5" />
      <Word word="L55TO5YOY" />
      <Word word="MO3M+L+" />     
    </div>
  );
};

export default App;