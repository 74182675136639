import React from 'react';
import { Fade } from '@mui/material';

const Letter = ({ letter, show }) => {
  return (
    <Fade in={show} timeout={{ enter: 1000, exit: 1000 }}>
      <span style={{ margin: '2px', display: 'inline-block', width: '20px', fontSize: '20px', fontWeight: 'bold' }}>
        {show ? letter : ''}
      </span>
    </Fade>
  );
};

export default Letter;