import React, { useState, useEffect } from 'react';
import Letter from './Letter';

const Word = ({ word }) => {
  const [letters, setLetters] = useState(word.split(''));
  const [currentLetterIndex, setCurrentLetterIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {

        let nextIndex;
        nextIndex = Math.floor(Math.random() * letters.length);
        setCurrentLetterIndex(nextIndex);

    }, 2000); // Includes time for the letter to appear and disappear

    return () => clearInterval(interval);
  }, [letters]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
      {letters.map((letter, index) => (
        <Letter key={index} letter={letter} show={index === currentLetterIndex} />
      ))}
    </div>
  );
};

export default Word;
